import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService, { UserDocument } from "../services/user.service";
import generalStyles from "../styles/generals.module.scss";
import SmallArrowImage from "../img/small-arrow.svg";
import noDocuments from "../img/no-documents.svg";
import styles from "../styles/pages/profile.module.scss";
import Loading from "../components/loading/Loading";
import inAppBrowser from "../services/mobile/browser";

export default function Documents({
  documents,
  loading,
}: {
  documents: UserDocument[];
  loading: boolean;
}) {
  const { t } = useTranslation("profile");
  const [augmentedDocuments, setAugmentedDocuments] = useState<
    (UserDocument & { documentUrl: string })[]
  >([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

  useEffect(() => {
    const fetchDocumentUrls = async () => {
      setLoadingDocuments(true);
      const augmentedDocs = await Promise.all(
        documents.map(async (document) => {
          const url = await UserService.getDocumentUrl(document);
          return {
            ...document,
            documentUrl: url.file_url,
          };
        })
      );
      setAugmentedDocuments(augmentedDocs);
      setLoadingDocuments(false);
    };

    fetchDocumentUrls();
  }, [documents]);

  return (
    <>
      <div className={`${generalStyles.title} ${generalStyles.align_center}`}>
        {t("documents")}
      </div>
      {augmentedDocuments && augmentedDocuments.length ? (
        <div className={styles.profile}>
          <div className={styles.items}>
            {augmentedDocuments.map((document, i) => {
              return (
                <div
                  className={`${styles.item} ${styles.itemWithBottomLine}`}
                  key={i}
                  onClick={(e) => {
                    inAppBrowser(document.documentUrl);
                  }}
                >
                  <div>
                    <div className={styles.itemCategory}>
                      {t(document.type_name)}
                    </div>
                    <div className={styles.itemText}>
                      {document.name.replace(/_/g, " ")}
                    </div>
                  </div>
                  <div className={styles.itemArrow}>
                    <img
                      src={SmallArrowImage}
                      height={20}
                      width={10}
                      alt="arrow"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.no_documents}>
            <h3>{loadingDocuments ? <Loading /> : t("no_documents")}</h3>
            <img src={noDocuments} width={270} height={300} />
          </div>
        </>
      )}
    </>
  );
}
